import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Form,
  Button,
  Col,
  Modal,
  Row,
  Dropdown,
  Pagination,
} from "react-bootstrap";
import { FaEye } from "react-icons/fa6";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Payouts = () => {
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch all payouts on component mount
  useEffect(() => {
    const fetchPayouts = async () => {
      try {
        const response = await axios.get(`https://fttunes.com/api/payouts`);
        console.log("Fetched payouts:", response.data);
        setPayouts(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch payouts");
        setLoading(false);
      }
    };

    fetchPayouts();
  }, []);

  const handleViewDetails = (payout) => {
    setSelectedPayout(payout);
    setShowModal(true);
  };

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // Pagination Logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = payouts.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(payouts.length / entriesPerPage);

  if (loading) {
    return <div>Loading payouts...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Col className="artists-table" style={{ margin: "30px 20px" }}>
      <Card>
        <Card.Header>
          <h4 className="card-title">
            <mark>Payouts</mark>
          </h4>
          <Button
            as={Link}
            to="/add-payout"
            className="theme-btn add_album_btn"
          >
            Make Payout
          </Button>
          {/* You can add a button here to create new payouts if needed */}
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Dropdown className="float-end">
              <Dropdown.Toggle variant="secondary">
                Show {entriesPerPage} rows
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[10, 50, 100].map((num) => (
                  <Dropdown.Item
                    key={num}
                    onClick={() => setEntriesPerPage(num)}
                  >
                    {num} rows
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Table responsive="md" variant="dark">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>
                    <Form.Check
                      type="checkbox"
                      id="checkAllPayouts"
                      className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                      style={{ border: "none" }}
                      label=""
                    />
                  </th>
                  <th>ID</th>
                  <th>Artist</th>
                  <th>Payout Date</th>
                  <th>Amount</th>
                  <th>Remarks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((payout, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`customCheckBoxPayout${index + 2}`}
                        className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                        label=""
                        style={{ border: "none" }}
                      />
                    </td>
                    <td>
                      <strong>{payout.payoutId}</strong>
                    </td>
                    <td>
                      {payout.firstName} {payout.lastName} ({payout.artistId})
                    </td>
                    <td>{new Date(payout.payoutDate).toLocaleDateString()}</td>
                    <td>₹ {payout.amount}</td>
                    <td>{payout.remarks}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleViewDetails(payout)}
                      >
                        <FaEye /> View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination variant="dark" className="justify-content-center mt-3">
              <Pagination.Prev
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              />
              <span className="mx-3">
                Showing {indexOfFirstEntry + 1} to{" "}
                {Math.min(indexOfLastEntry, payouts.length)} of {payouts.length}
              </span>
              <Pagination.Next
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </Card.Body>
      </Card>

      {/* Modal to show payout details */}
      {selectedPayout && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
          className="change_status_modal"
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#1a1a1a",
              borderBottom: "1px solid #444",
              color: "#fff",
            }}
          >
            <Modal.Title>
              Payout Details -{" "}
              <span style={{ color: "#76009f" }}>
                {selectedPayout.payoutId}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#2b2b2b", color: "#fff" }}>
            <Row>
              <Col md={6}>
                <div
                  style={{
                    padding: "15px",
                    backgroundColor: "#1a1a1a",
                    borderRadius: "8px",
                    marginBottom: "20px",
                    border: "1px solid #444",
                  }}
                >
                  <h5 style={{ color: "#76009f", marginBottom: "15px" }}>
                    Artist Information
                  </h5>
                  <p>
                    <strong>Name:</strong>{" "}
                    <span style={{ color: "#dcdcdc" }}>
                      {selectedPayout.firstName} {selectedPayout.lastName}
                    </span>
                  </p>
                  <p>
                    <strong>Artist ID:</strong>{" "}
                    <span style={{ color: "#dcdcdc" }}>
                      {selectedPayout.artistId}
                    </span>
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div
                  style={{
                    padding: "15px",
                    backgroundColor: "#1a1a1a",
                    borderRadius: "8px",
                    border: "1px solid #444",
                  }}
                >
                  <h5 style={{ color: "#76009f", marginBottom: "15px" }}>
                    Payout Information
                  </h5>
                  <p>
                    <strong>Payout Date:</strong>{" "}
                    <span style={{ color: "#dcdcdc" }}>
                      {new Date(selectedPayout.payoutDate).toLocaleDateString()}
                    </span>
                  </p>
                  <p>
                    <strong>Amount:</strong>{" "}
                    <span style={{ color: "#dcdcdc" }}>
                      ₹ {selectedPayout.amount}
                    </span>
                  </p>
                  <p>
                    <strong>Payout Period:</strong>{" "}
                    <span style={{ color: "#dcdcdc" }}>
                      {new Date(
                        selectedPayout.payoutStartDate
                      ).toLocaleDateString()}{" "}
                      -{" "}
                      {new Date(
                        selectedPayout.payoutEndDate
                      ).toLocaleDateString()}
                    </span>
                  </p>
                  <p>
                    <strong>Remarks:</strong>{" "}
                    <span style={{ color: "#dcdcdc" }}>
                      {selectedPayout.remarks}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
            {selectedPayout.receipt && (
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <a
                  href={`https://fttunes.com/api/${selectedPayout.receipt}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    variant="outline-dark"
                    style={{
                      borderColor: "#76009f",
                      color: "#76009f",
                    }}
                  >
                    Download Receipt
                  </Button>
                </a>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: "#1a1a1a",
              borderTop: "1px solid #444",
            }}
          >
            <Button
              variant="outline-light"
              onClick={() => setShowModal(false)}
              style={{
                borderColor: "#76009f",
                color: "#76009f",
                marginRight: "10px",
              }}
            >
              Close
            </Button>
            {/* Add any additional actions if necessary */}
          </Modal.Footer>
        </Modal>
      )}
    </Col>
  );
};

export default Payouts;
