import React, { useEffect } from "react";
import "./Dashboard.css";
import Earnings from "./Earnings";
import Navbar from "./Navbar";
import Streams from "./Streams";
// import TargetAudience from "./TargetAudience";
import TopReleases from "./TopReleases";
import ReleaseReq from "./ReleaseReq";
import RecentSignups from "./RecentSignups";
// import scrollreveal from "scrollreveal";

const Dashboard = () => {
  // useEffect(() => {
  //   const sr = scrollreveal({
  //     origin: "bottom",
  //     distance: "80px",
  //     duration: 2000,
  //     reset: false,
  //   });
  //   sr.reveal(
  //     `
  //       nav,
  //       .row,
  //       .row2
  //   `,
  //     {
  //       opacity: 0,
  //       interval: 100,
  //     }
  //   );
  // }, []);

  return (
    <section id="dashboard" className="section">
      <Navbar />
      <div className="grid">
        <div className="row2">
          {/* <TargetAudience /> */}
          {/* <ReleaseReq /> */}
          <RecentSignups />
        </div>
        <div className="row2">
          {/* <RecentSignups /> */}
          <ReleaseReq />
        </div>

        <div className="row2">
          {/* <TargetAudience /> */}
          <TopReleases />
        </div>
        <div className="roww">
          <Streams />
          <Earnings />
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
