import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card, Row, Col, Table } from "react-bootstrap";
import "./Ticket.css";

const AllTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    // Fetch all tickets
    axios.get("https://fttunes.com/api/support/tickets").then((res) => {
      if (res.data.success) {
        setTickets(res.data.tickets);
      }
    });

    // Fetch all artists
    axios.get("https://fttunes.com/api/artists").then((res) => {
      if (res.data) {
        setArtists(res.data);
      }
    });
  }, []);

  // Ticket statistics
  const totalTickets = tickets.length;
  const resolvedTickets = tickets.filter(
    (ticket) => ticket.status === "Closed"
  ).length;
  const pendingTickets = tickets.filter(
    (ticket) => ticket.status === "In Progress"
  ).length;
  const openTickets = tickets.filter(
    (ticket) => ticket.status === "Open"
  ).length;

  console.log("artists", artists);

  return (
    <div className="admin-container tickets">
      <h2 className="title">All Support Tickets</h2>

      {/* Ticket Stats */}
      <Row className="ticket-stats">
        <Col md={3}>
          <Card className="stat-card bg-secondary text-white">
            <Card.Body>
              <Card.Title>Total Tickets</Card.Title>
              <Card.Text>{totalTickets}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="stat-card bg-success text-white">
            <Card.Body>
              <Card.Title>Resolved Tickets</Card.Title>
              <Card.Text>{resolvedTickets}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="stat-card bg-warning text-white">
            <Card.Body>
              <Card.Title>Pending Tickets</Card.Title>
              <Card.Text>{pendingTickets}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="stat-card bg-danger text-white">
            <Card.Body>
              <Card.Title>Open Tickets</Card.Title>
              <Card.Text>{openTickets}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* All Tickets */}
      <div className="table-container">
        <Table striped bordered hover variant="dark" responsive>
          <thead>
            <tr>
              <th>Ticket ID</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Raised By</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => {
              const artist = artists.find((a) => a.artistId === ticket.artistId);
              return (
                <tr key={ticket.ticket_id}>
                  <td>
                    <Link to={`/tickets/${ticket.ticket_id}`} className="ticket-link">
                      {ticket.ticket_id}
                    </Link>
                  </td>
                  <td>{ticket.subject}</td>
                  <td>
                    <span className={`status ${ticket.status.toLowerCase()}`}>
                      {ticket.status}
                    </span>
                  </td>
                  <td>{new Date(ticket.created_at).toLocaleString()}</td>
                  <td>
                    {artist ? `${artist.firstName} ${artist.lastName}` : "N/A"}
                  </td>
                  <td>{artist ? artist.email : "N/A"}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AllTickets;
