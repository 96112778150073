import React, { useEffect, useState } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Artists from "./components/artists/Artists";
import AddArtist from "./components/artists/AddArtist";
import ArtistProfile from "./components/artists/ArtistProfile";
import EditArtist from "./components/artists/EditArtist";
// import Login from "./components/login/Login";
import ProtectedRoute from "./ProtectedRoute";
import AddFtTrack from "./components/albums/AddFtTrack";
import AllFtTracks from "./components/albums/AllFtTracks";
import AlbumDetails from "./components/albums/AlbumDetails";
import EditFttrack from "./components/albums/EditFttrack";
import NewFttrack from "./components/albums/NewFttrack";
import Payouts from "./components/payouts/Payouts";
import AddPayouts from "./components/payouts/AddPayouts";
import NewFttrackID from "./components/albums/NewFttrackID";
import AddPayoutsID from "./components/payouts/AddPayoutsID";
import { jwtDecode } from "jwt-decode";
import UploadReportID from "./components/reports/UploadReportID";
import AllTickets from "./components/ticket/AllTickets";
import TicketChat from "./components/ticket/TicketChat";

export default function App() {
  // useEffect(() => {
  //   const sr = scrollreveal({
  //     origin: "left",
  //     distance: "80px",
  //     duration: 1000,
  //     reset: false,
  //   });
  //   sr.reveal(
  //     `
  //      #sidebar
  //   `,
  //     {
  //       opacity: 0,
  //     }
  //   );
  //   const sr2 = scrollreveal({
  //     origin: "right",
  //     distance: "80px",
  //     duration: 1000,
  //     reset: false,
  //   });
  //   sr2.reveal(
  //     `
  //      #rightSidebar
  //   `,
  //     {
  //       opacity: 0,
  //     }
  //   );
  // }, []);
  // const token = localStorage.getItem("token");
  // const decodedToken = jwtDecode(token);
  // const currentTime = Date.now() / 1000; // current time in seconds

  // const isAuthenticated = localStorage.getItem("token"); // Check if the user is authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const token = localStorage.getItem("token");

  useEffect(() => {
    const checkAuthenticated = () => {
      console.log("isauthenticated called");
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // current time in seconds

        if (decodedToken.exp < currentTime) {
          // Token is expired
          // localStorage.removeItem("token"); // Clear expired token
          setIsAuthenticated(false);
          return;
        }
      } catch (error) {
        // If there's an error decoding the token (e.g., corrupted token), treat it as expired
        // localStorage.removeItem("token");
        setIsAuthenticated(false);
        return;
      }

      setIsAuthenticated(true);
      return;
    };

    checkAuthenticated();
  }, [isAuthenticated]);
  console.log("isauthenticated", isAuthenticated);

  return (
    <div className={`app-container ${!isAuthenticated ? "no_sidebar" : ""}`}>
      {isAuthenticated && <Sidebar />}
      <div style={{minHeight:"100vh", background:"#201f1f"}}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Home />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/artists"
            element={
              <ProtectedRoute>
                <Artists />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-artist"
            element={
              <ProtectedRoute>
                <AddArtist />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/artists/:id"
            element={
              <ProtectedRoute>
                <ArtistProfile />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/artists/edit/:id"
            element={
              <ProtectedRoute>
                <EditArtist />{" "}
              </ProtectedRoute>
            }
          />

          <Route
            path="/add-fttrack"
            element={
              <ProtectedRoute>
                <NewFttrack />{" "}
              </ProtectedRoute>
            }
          />

          <Route
            path="/add-fttrack/:id"
            element={
              <ProtectedRoute>
                <NewFttrackID />{" "}
              </ProtectedRoute>
            }
          />

          <Route
            path="/fttracks"
            element={
              <ProtectedRoute>
                <AllFtTracks />{" "}
              </ProtectedRoute>
            }
          />

          <Route
            path="/fttrack/:id"
            element={
              <ProtectedRoute>
                <AlbumDetails />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/fttrack/edit/:id"
            element={
              <ProtectedRoute>
                <EditFttrack />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/payouts"
            element={
              <ProtectedRoute>
                <Payouts />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-payout"
            element={
              <ProtectedRoute>
                <AddPayouts />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-payout/:id"
            element={
              <ProtectedRoute>
                <AddPayoutsID />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload-report/:id"
            element={
              <ProtectedRoute>
                <UploadReportID />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/tickets"
            element={
              <ProtectedRoute>
                <AllTickets />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tickets/:ticketId"
            element={
              <ProtectedRoute>
                <TicketChat />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}
