import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import "./Ticket.css";

const TicketChat = () => {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState(null);
  const [artist, setArtist] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const chatEndRef = useRef(null); // Ref for auto-scrolling

  useEffect(() => {
    // Fetch ticket details
    axios.get("https://fttunes.com/api/support/tickets").then((res) => {
      if (res.data.success) {
        const foundTicket = res.data.tickets.find(
          (t) => t.ticket_id === ticketId
        );
        if (foundTicket) {
          setTicket(foundTicket);

          // Fetch artist details based on artistId from the ticket
          axios.get("https://fttunes.com/api/artists").then((artistRes) => {
            if (artistRes.data) {
              const foundArtist = artistRes.data.find(
                (a) => a.artistId === foundTicket.artistId
              );
              setArtist(foundArtist);
            }
          });
        }
      }
    });

    // Fetch chat messages
    axios
      .get(`https://fttunes.com/api/support/tickets/${ticketId}/messages`)
      .then((res) => {
        if (res.data.success) {
          setMessages(res.data.messages);
        }
      });
  }, [ticketId]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = () => {
    if (newMessage.trim()) {
      axios
        .post("https://fttunes.com/api/support/tickets/messages", {
          ticketId,
          senderId: "admin",
          receiverId: "artist",
          message: newMessage,
        })
        .then(() => {
          setNewMessage("");
          axios
            .get(
              `https://fttunes.com/api/support/tickets/${ticketId}/messages`
            )
            .then((res) => {
              setMessages(res.data.messages);
            });
        });
    }
  };

  const closeTicket = () => {
    axios
      .patch("https://fttunes.com/api/support/tickets/close", { ticketId })
      .then(() => {
        alert("Ticket closed successfully");
        if (ticket) setTicket({ ...ticket, status: "Closed" });
      });
  };

  return (
    <div className="admin-chat-container">
      <h1 className="mb-3">Ticket Chat - {ticketId}</h1>
      <Row>
        <Col md={9}>
          {/* Chat Messages */}
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-bubble ${
                  msg.sender_id === "admin" ? "sent" : "received"
                }`}
              >
                <p>{msg.message}</p>
                {msg.remarks && <small>Remarks: {msg.remarks}</small>}
                <small>{new Date(msg.created_at).toLocaleString()}</small>
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>

          {/* Message Input */}
          <div className="chat-input">
            <Form.Control
              as="textarea"
              placeholder="Type your reply..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              style={{ height: "100px", background: "#333", color: "#fff", width:"85%" }}
            />
            <div style={{display:"flex", flexDirection:"column", gap:"5px", width:"15%"}}>
              <Button onClick={sendMessage} variant="primary">
                Send
              </Button>
              <Button
                onClick={closeTicket}
                variant="danger"
                className="close-btn"
              >
                Close Ticket
              </Button>
            </div>
          </div>
        </Col>

        <Col md={3}>
          {/* Ticket & Artist Details Side by Side */}
          <Row>
            <Col md={12}>
              {ticket && (
                <Card className="ticket-details-card bg-secondary text-white">
                  <Card.Body>
                    <Card.Title>Ticket Details</Card.Title>
                    <Card.Text>
                      <strong>Id:</strong> {ticket.ticket_id}
                    </Card.Text>
                    <Card.Text>
                      <strong>Subject:</strong> {ticket.subject}
                    </Card.Text>
                    <Card.Text
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <strong>Status:</strong>
                      <span
                        className={`status ${ticket.status.toLowerCase()}`}
                        style={{ width: "fit-content" }}
                      >
                        {ticket.status}
                      </span>
                    </Card.Text>
                    <Card.Text>
                      <strong>Created At:</strong>{" "}
                      {new Date(ticket.created_at).toLocaleString()}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>

            <Col md={12}>
              {artist && (
                <Card className="artist-details-card bg-dark text-white">
                  <Card.Body>
                    <Card.Title>Artist Details</Card.Title>
                    <Card.Text>
                      <strong>Name:</strong> {artist.firstName}{" "}
                      {artist.lastName}
                    </Card.Text>
                    <Card.Text>
                      {artist.role}
                      <br />
                      {artist.description}
                      {artist.description && <br />}
                      {artist.address}
                      {artist.address && <br />}
                      {artist.city}
                      {artist.city && ", "}
                      {artist.state}
                      {artist.state && ", "}
                      {artist.pincode}
                      <br />
                      {artist.country}
                      {artist.country && <br />}
                      {artist.plan_purchased}
                      {artist.plan_purchased && <br />}
                      ID: {artist.artistId}
                    </Card.Text>
                    <Card.Text>
                      <strong>Email:</strong> {artist.email}
                    </Card.Text>
                    <Card.Text>
                      <strong>Phone:</strong> {artist.phone}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TicketChat;
