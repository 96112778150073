import React from "react";
import { Container, Row } from "react-bootstrap";
import AllArtistsTable from "./AllArtistsTable";

const Artists = () => {
  return (
    <div className="artists-container" style={{padding:"0.5rem"}}>
      <Container>
        <Row>
          <AllArtistsTable />
        </Row>
      </Container>
    </div>
  );
};

export default Artists;
